@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.hours {
  @include ui.XSleft;
  border: solid 1px ui.$contrast-grey-medium;
  border-radius: 0.25rem;
  padding: 1rem;
  height: 3.375rem;
}

.textArea {
  width: 100%;
}

.reportHeader {
  @include ui.Sleft;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.reportLength {
  @include ui.XSleft;
}

.planificationButton {
  margin-top: 1.25rem;
}

.typesSelector {
  margin-bottom: 2rem;
}

.modalIcon {
  margin-left: 1.5rem;
}

.planification {
  padding-top: 1.75rem;
  display: flex;
  flex-direction: row;
}

.planification div + div {
  margin-left: 1rem;
}

.searchInputContainer {
  border-radius: 0.25rem 0.25rem 0 0;
}

.textInputContainer {
  border-top: 0px;
  border-radius: 0 0 0.25rem 0.25rem;
}
