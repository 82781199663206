@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}

.mobileContainer {
  flex-wrap: wrap;
  gap: 0.5rem;
}

.unselectedButton {
  border: 1px solid ui.$contrast-grey-strong;
}

.mobileUnselectedButton {
  height: 3.5rem;
  flex-grow: 1;
}

.primaryButton {
  height: 3.5rem;
  border-width: 1px;
}

.mobilePrimaryButton {
  flex-grow: 1;
}

.unselectedButton:hover {
  border-color: ui.$main-blue-full;
}
