@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 11rem;
  border-radius: 8px;
  background-color: ui.$main-beige-full;
  overflow: hidden;
  padding-top: 0.658rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.topPart {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.headerRight {
  margin-right: -0.25rem;
  margin-top: -0.5rem;
}

.title {
  @include ui.Sleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.25rem;
}

.subtitle {
  @include ui.XSgrey_mediumleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space: pre-line;
  margin-bottom: 1rem;
}

.description {
  @include ui.XSnavyleft;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
