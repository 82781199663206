@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: ui.$main-beige-full;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.headerSection {
  padding-left: 2rem;
  padding-right: 1rem;
  background-color: ui.$main-beige-full;
}

.mobileContainer {
  background-color: ui.$main-white-full;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.appName {
  @include ui.Sblueleft;
}

.title {
  @include ui.Lleft;
}

.contactBook {
  display: flex;
  overflow: hidden;
  flex: 1;
  position: relative;
  padding: 1rem 4rem 0rem 2rem;
  background-color: ui.$main-white-full;
}

.contactList {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.contactList::-webkit-scrollbar {
  display: none;
}

.alphabetPickerContainer {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
}

.noResult {
  margin-top: 1rem;
}

.mobileBook {
  padding: 1rem 2.5rem 1rem 1rem;
  position: static;
}

.mobileAlphabetPickerContainer {
  top: 5rem;
}
