@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
}

.border {
  width: 0.25rem;
  height: 2.5rem;
  border-radius: 0px 4px 4px 0px;
  background-color: ui.$main-white-full;
  margin-right: 0.25rem;
}

.bottomButtons {
  margin-top: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 40px;
}

.parameterButton {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 2.5rem;
}

.appsButton {
  margin-top: 0.5rem;
}

.icon {
  margin-left: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.retractButton {
  display: flex;
  flex-direction: row;
  height: 3rem;
  width: 110%;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  background-color: ui.$main-blue-full;
  color: ui.$main-white-medium;
  cursor: pointer;
}

.retractIcon {
  height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.text {
  @include ui.Swhitefullleft;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
