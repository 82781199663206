.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.checkbox {
  flex: 1 0 40%;
  padding-bottom: 1rem;
}

.modalIcon {
  margin-top: -1.5rem;
}
