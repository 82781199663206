@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  width: 100%;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: ui.$contrast-grey-medium;
}
