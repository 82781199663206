@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.XSgrey_mediumleft;
  text-align: left;
}

.error {
  @include ui.XSerrorleft;
  text-align: left;
}

.PerimeterContainer {
  display: flex;
  width: 100%;

  div:not(:last-child) {
    margin-right: 1rem;
  }
}

//DropDownButton
.dropdown {
  @include ui.Snavyleft;
  position: relative;
  display: inline-block;
  border-radius: 0.25rem;
  border-color: ui.$contrast-grey-medium;
  border-width: 0.1rem;
  border-style: solid;
  flex: 1;
  text-align: left;
}

.dropDownTextAndArrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.arrowSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verticalSeparator {
  background-color: ui.$contrast-grey-medium;
  width: 0.125rem;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
}

.arrowDown {
  border-left-color: transparent;
  border-left-width: 0.5rem;
  border-right-color: transparent;
  border-right-width: 0.5rem;
  border-top-color: ui.$main-blue-full;
  border-top-width: 0.5rem;
  border-style: solid;
  border-bottom-width: 0rem;
  height: 0rem;
  width: 0rem;
}

//DropDownContent
.dropDownText {
  @include ui.Snavyleft;
}

.dropDownTextDisabled {
  @include ui.Sgrey_mediumleft;
}

.separator {
  @extend %separator;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: ui.$main-white-full;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  z-index: 3;
  width: 100%;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContentButton {
  padding: 1rem;
  border: none;
}
