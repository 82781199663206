@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.688rem;
}

.title {
  @include ui.XLnavyleft;
  margin-bottom: 1.5rem;
}
