@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textField {
  margin-top: 1rem;
}

.firstTextField {
  margin-top: 0.5rem;
}

.text {
  @include ui.Snavyleft;
}

.modalIcon {
  margin-top: -1.5rem;
}
