@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.XLnavyleft;
  margin-bottom: 0.5rem;
}

.firstLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hideableContainer {
  background-color: ui.$main-beige-full;
}

.colorBars {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.unroll {
  div {
    height: fit-content !important;
  }
}

.yellowBar {
  width: 22rem;
  height: 0.25rem;
  background-color: ui.$main-yellow-full;
}

.greyBar {
  height: 1px;
  width: calc(100% - 22rem);
  background-color: ui.$contrast-grey-medium;
}

.button {
  padding: 0.5rem;
  border-width: 0rem;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  min-width: 0rem;
  border-radius: 8px;
}

.subMenuIcon {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
}
