@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  position: absolute;
  background-color: ui.$main-white-full;
  border-radius: 8px 0px 0px 8px;
  top: 0;
  right: 0;
  height: 100%;
  width: 57%;
  min-width: 33.75rem;
  max-width: calc(100% - 16.5rem);
  z-index: 3;
  padding: 1.5rem;
}

.overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.closeButton {
  padding: 0.5rem;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 0px;
  position: relative;
  left: -0.5rem;
}

.illus {
  position: absolute;
  right: 0;
  top: 2.5rem;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.underConstruction {
  padding-left: 1.5rem;
  margin-top: 1rem;
}
