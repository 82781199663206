.container {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  overflow-y: scroll;
}

.button {
  height: 3.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}
