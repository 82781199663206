@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gearButton {
  color: ui.$main-white-medium !important;
  padding: 0.5rem !important;
  border-width: 0rem !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
  min-width: 0rem !important;
}

.openGearButton {
  outline: none;
  background-color: ui.$contrast-grey-lighter !important;
}

.gearIcon {
  color: ui.$main-white-full !important;
}

.menuItem {
  @include ui.Sblueleft;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: currentColor;
    }
  }
}

.menuItem:hover:not(:active),
.menuItem:focus-visible {
  background-color: ui.$contrast-grey-lighter;
  cursor: pointer;
}

.menuItem:active {
  background-color: ui.$contrast-grey-light;
}

.spacer {
  width: 0.5rem;
}

.separator {
  height: 1px;
  background-color: ui.$contrast-grey-medium;
}

.version {
  padding-top: 1rem;
  @include ui.XSgrey_mediumleft;
}
