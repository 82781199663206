.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formLine {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.dropdown {
  width: 100%;
}

.mobileDropDownContainer {
  margin-top: 1rem;
}
