@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.switchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1.75rem;
  height: 2.5rem;
}

.section {
  margin-top: 1rem;
}

.switchLabel {
  @include ui.Snavyleft;
  width: calc(100% - 4rem);
}

.textLength {
  @include ui.XSnavyleft;
}

.comment {
  width: 100%;
  height: 10rem;
}
