@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ui.$contrast-grey-medium;
  border-radius: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 5.75rem;
  cursor: pointer;
  width: 100%;
}

.mobileContainer {
  height: 7rem;
  border: none;
  background-color: ui.$main-beige-full;
  border-radius: 0px;
}

.container:hover {
  border: 2px solid ui.$main-blue-full;
  margin: -1px;
  height: calc(5.75rem + 2px);
  padding-left: calc(0.75rem - 1px);
  padding-right: calc(0.75rem - 1px);
}

.mobileContainer:hover {
  border: 2px solid ui.$main-blue-full;
  margin: -2px;
  height: calc(7rem + 4px);
  padding-left: calc(0.75rem - 2px);
  padding-right: calc(0.75rem - 2px);
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.title {
  @include ui.Snavyleft;
  margin-bottom: 0.25rem;
}

.subTitle {
  @include ui.XSgrey_mediumleft;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
