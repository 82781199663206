@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.list {
  margin-top: 1rem;
  @include ui.hide-scrollbar;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1px;
}

.container {
  margin-top: 1rem;
}

.securityContainer {
  height: calc(100vh - 20rem);
}

.mobileSecurityContainer {
  height: calc(100vh - 24.75rem);
}

.noResult {
  @include ui.Snavyleft;
  padding-top: 1rem;
}

.loaderSpace {
  margin-bottom: 0.5rem;
}

.button {
  width: 100%;
}
