@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.menuContainer {
  height: 3rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.icon {
  color: ui.$contrast-grey-light;
  path {
    fill: ui.$contrast-grey-light;
  }
}

.tabsContainer {
  width: 100%;
  height: 3rem;
}
