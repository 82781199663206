@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.modalIcon {
  margin-left: 1.5rem;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.mobileLine {
  gap: 0.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toggleSwitchLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.replacementDurationSpacing {
  width: 2rem;
}

.monthLabelContainer {
  width: 12.5rem;
  height: 3.5rem;
  margin-bottom: 1rem;
  input {
    max-width: 10.5rem;
  }
}

.mobileMonthLabelContainer {
  width: 12rem;
}

.monthLabel {
  @include ui.Snavyleft;
}

.datePickerContainer {
  width: 12.5rem;
}

.textArea {
  width: 100%;
  margin-top: 1rem;
}

.mobileReplacementContainer {
  display: flex;
  flex-direction: column;
}

.mobileAbsenceResult {
  margin-top: 0.75rem;
}
