.line {
  margin-bottom: 1rem;
}

.textArea {
  width: 100%;
}

.modalIcon {
  margin-top: -1.5rem;
}

.searchInputContainer {
  border-radius: 0.25rem 0.25rem 0 0;
}

.textInputContainer {
  border-top: 0px;
  border-radius: 0 0 0.25rem 0.25rem;
}
