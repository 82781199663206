@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  min-height: 5.75rem;
  border-radius: 8px;
  background-color: ui.$main-beige-full;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  padding: 0.875rem 0.7rem;
  padding-right: 0.5rem;
  justify-content: space-between;
}

.badge {
  margin-right: 1.2rem;
}

.title {
  @include ui.Sleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  @include ui.XSgrey_mediumleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space: pre-line;
}

.description {
  @include ui.XSnavyleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.5rem;
  height: 1rem;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: calc(100% - 4.5rem);
}

.leftSide {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
