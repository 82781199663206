@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.button {
  color: ui.$main-white-medium !important;
  padding: 0.5rem !important;
  border-width: 0rem !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
  min-width: 0rem !important;
}

.openButton {
  outline: none !important;
  background-color: ui.$contrast-grey-lighter !important;

  svg {
    path {
      fill-opacity: unset !important;
    }
  }
}

.buttonIcon {
  color: ui.$main-white-full !important;
}
