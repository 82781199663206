@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.sideBar {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ui.$main-navy-full;
  position: relative;
}

.titleWithLogo {
  display: flex;
  flex-direction: row;
  overflow: visible;
}

.logo {
  align-self: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 2rem;
  height: 2rem;
}

.logo img {
  width: 2rem;
  height: 2rem;
}

.title {
  @include ui.Mwhiteleft;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.verticalSeparator {
  background-color: ui.$contrast-grey-medium;
  width: 0.08rem;
  height: 30%;
  align-self: center;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.menuContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.whiteDash {
  height: 0.063rem;
  background-color: white;
  margin-left: 1.2rem;
  width: 1rem;
}

.beforeSubMenuSeparator {
  height: 0.063rem;
  position: relative;
  top: -0.5rem;
}

.afterSubMenuSeparator {
  height: 0.063rem;
  margin-bottom: 0.5rem;
}
