@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  flex-direction: row;
  margin-left: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  opacity: 0.6;
  overflow: hidden;
}

.selected {
  border-radius: 4px;
  background-color: ui.$main-navy-medium;
  opacity: 1;
}

.whiteBorder {
  height: 2.5rem;
  min-width: 0.25rem;
  border-radius: 0px 4px 4px 0px;
  background-color: ui.$main-white-full;
}

.transparentBorder {
  height: 2.5rem;
  min-width: 0.25rem;
  background-color: transparent;
}

.childrenSelected {
  opacity: 1;
}

.hasNoChildren {
  height: 2.5rem;
  margin-bottom: 0.5rem;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rightIcon {
  margin-left: 0.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.text {
  @include ui.Swhitefullleft;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.textWithChildren {
  font-size: 12px;
  height: 16px;
}

.triangle {
  display: inline-block;
  margin-left: auto;
  height: 0;
  width: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid ui.$main-beige-full;
  border-bottom: 8px solid transparent;
  position: absolute;
  right: -0.5rem;
  top: 0.75rem;
}
