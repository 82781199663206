@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  width: 100%;
}

.separator {
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: ui.$contrast-grey-medium;
}

.badgesContainer {
  overflow-x: scroll;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  white-space: nowrap;
  height: 1.5rem;
  min-height: 1.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.badgesContainer::-webkit-scrollbar {
  display: none;
}

.badge {
  margin-right: 0.5rem;
  background-color: transparent;
  color: ui.$main-blue-full;
  border: solid 2px ui.$main-blue-full;
  height: 1.5rem;
}

.selectedBadge {
  background-color: ui.$main-navy-full;
  color: ui.$main-white-full;
  border: solid 2px ui.$main-navy-full;
}
