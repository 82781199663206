@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 6rem;
  border-radius: 8px;
  background-color: ui.$main-beige-full;
  border: solid 1px ui.$contrast-grey-medium;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-clip: padding-box;
}

.container:hover {
  border: solid 2px ui.$main-blue-full;
}

.container:hover .descriptionContainer {
  padding-right: calc(1.2rem - 1px);
}

.container:hover .leftContainer {
  padding-left: calc(1.2rem - 1px);
}

.icon {
  padding-right: 1.2rem;
}

.subContainer {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  overflow: hidden;
}

.title {
  @include ui.Sleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  @include ui.XSgrey_mediumleft;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space: pre-line;
}

.descriptionContainer {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.description {
  @include ui.Mnavyleft;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 1rem;
}

.leftContainer {
  padding-left: 1.2rem;
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
